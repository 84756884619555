<template>
  <div class="idxBox column" v-if="detailInfo">
    <el-scrollbar class="grow hid">
      <div v-infinite-scroll="getList" :infinite-scroll-immediate="false" :infinite-scroll-disabled="current > last_page">
        <div class="liveVideoBox divFlex">
          <!-- <live-player :video-url="detailInfo.videoFile" live="true" fluent stretch aspect="fullscreen" autoplay="true"
          id="player01">
        </live-player> -->
          <SwiperImage3 v-if="detailInfo.images || detailInfo.videoFile" idStr="乡村详情" :images="detailInfo.images ? detailInfo.images.split(',') : []"
            :videos="detailInfo.videoFile ? detailInfo.videoFile.split(',') : []" />
        </div>
        <div class="playStep bet">
          <span @click="getPrevCounty()"> &lt;&lt; 上一村 </span>
          <span> {{ detailInfo.name }} </span>
          <span @click="getNextCounty()"> >> 下一村 </span>
        </div>
        <div class="playProcess divFlex">
          <div class="voice column">
            <span class="voiceText">语音讲解</span>
            <div class="processBar">
              <div class="activeLine" :style="`width:` + (Number(nowTime) / Number(allTime) * 100 + '%')">
                <div class="dot"></div>
              </div>
            </div>
            <audio id="eventAudio" style="pointer-events: none;" v-show="false" :src="detailInfo.audioFile" autoplay
              controls>
            </audio>
            <div class="startEndSec bet">
              <span> {{ getStr(this.nowTime) }} </span>
              <span>{{ getStr(detailInfo.audioLength) }}</span>
            </div>
          </div>

          <img @click="togglePlay()" class="playBtn"
            :src="play ? require('@/assets/img/pause.png') : require('@/assets/img/play.png')" alt="">
          <!-- <img v-else class="playBtn" src="@/assets/img/pause.png" alt=""> -->
        </div>
        <div class="div20"></div>
        <div class="countyContent" v-html="detailInfo.introduce">

        </div>
        <div class="div30"></div>
        <div class="div20"></div>

        <div class="newAction column">
          <div class="newTitle">新闻动态</div>
          <div class="div40"></div>
          <div @click="seeThisDetail(news)" class="divFlex grow oneNew" v-for="(news, idx) in newList"
            :key="idx + 'gfhjkhkhjolipdf'">
            <img v-if="news.picture" class="avatar" :src="news.picture" alt="">
            <img v-else class="avatar" src="@/assets/img/123.png" alt="">
            <div class="rightContent column flexBet">
              <div class="contentText">{{ news.title }}</div>
              <div class="bet timeDetail">
                <span>{{ news.releaseTime.split(' ')[0] }}</span>
                <span>详情 >></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>


  </div>
</template>
  
<script>
import {
  getDataDetail,
  bglist,
  getNextDetail,

} from "@/api";
import { SwiperImage3 } from "@/components";
export default {
  components: {
    SwiperImage3
  },
  data() {
    return {
      detailInfo: false,
      nowTime: 0,
      allTime: 10,
      timer: false,
      play: true,
      current: 1,
      last_page: 10,
      newList: [],
      id: false
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.id = this.$route.query.id;
    // console.log(id);
    this.$nextTick(() => {
      this.getDetail()
      this.getList()
    })




  },
  methods: {
    getNextCounty() {
      clearInterval(this.timer);
      this.getNextDetailInfo(Number(this.detailInfo.sort) + 1);
    },
    getPrevCounty() {
      clearInterval(this.timer)
      this.getNextDetailInfo(Number(this.detailInfo.sort) - 1)
    },

    getNextDetailInfo(sort) {
      let obj;
      if (sort) obj = { sort }
      getNextDetail(obj).then(res => {
        this.detailInfo = res.data;
        this.id = res.data.id;
        this.current = 1;
        this.last_page = 10;
        this.newList = []
        this.nowTime = 0;
        this.play = true;
        this.getList()
        this.allTime = Number(res.data.audioLength);
        if (!res.data.audioFile) return;
        this.timer = setInterval(() => {
          if (this.nowTime >= this.allTime) {
            clearInterval(this.timer)
            return
          }
          this.nowTime += 1;

        }, 1000);
      });
    },
    getDetail(id) {

      getDataDetail({ id: this.id }).then(res => {
        this.detailInfo = res.data;
        this.allTime = Number(res.data.audioLength);
        if (!res.data.audioFile) return;
        this.timer = setInterval(() => {
          if (this.nowTime >= this.allTime) {
            clearInterval(this.timer)
            return
          }
          this.nowTime += 1;

        }, 1000);
      });
    },
    seeThisDetail(val) {
      this.$router.push({
        path: '/ActionDetail',
        query: {
          id: val.id
        }
      })
    },
    getList() {
      let obj = { id: this.id, current: this.current++, size: 20 }
      bglist(obj).then(res => {
        let { pages, records } = res.data
        this.last_page = pages
        this.newList = [...this.newList, ...records];
      })
    },
    async ountAudioTime() {
      let audio = document.getElementById('eventAudio')

      // while (isNaN(audio.duration) || audio.duration === Infinity) {
      //   // 延迟一会 不然网页都卡死
      //   await new Promise(resolve => setTimeout(resolve, 200));
      //   // 设置随机播放时间，模拟调进度条
      //   audio.currentTime = 10000000 * Math.random();
      // }
    },
    togglePlay() {
      this.play = !this.play;
      let audio = document.getElementById('eventAudio');
      // this.play ? audio.paused() : audio.play()
      console.log(audio.paused)
      if (!this.play) {
        audio.pause()
        clearInterval(this.timer)
      } else {
        audio.play()
        this.timer = setInterval(() => {
          if (this.nowTime >= this.allTime) {
            clearInterval(this.timer)
            return
          }
          this.nowTime += 1;

        }, 1000);
      }
    },
    getStr(num) {
      let hour = Math.floor(num / 3600);
      let moreMin = num % 3600; // 去除小时   多余的秒数  0
      let min = Math.floor(moreMin / 60) > 9 ? Math.floor(moreMin / 60) : 0 + '' + Math.floor(moreMin / 60)  // 显示的分钟数
      let sec = moreMin % 60 > 9 ? moreMin % 60 : 0 + '' + moreMin % 60;

      if (hour) return hour + ":" + min + ':' + sec
      return min + ':' + sec
    }
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer)
  }
};
</script>
<style lang="scss" scoped>
.liveVideoBox {
  width: 100%;
  height: 4rem;
  background: #FFFFFF;
  border-radius: 0.1rem;
  position: relative;

}

.playStep {
  width: 100%;
  padding: 0 0.27rem;
  box-sizing: border-box;
  margin-top: 0.5rem;
  cursor: pointer;

  span:nth-child(1) {
    font-size: 0.36rem;
    font-family: SourceHanSerifCNHeavy;
    font-weight: bold;
    color: #654D36;
  }

  span:nth-child(2) {
    font-size: 0.48rem;
    font-family: SourceHanSerifCNHeavy;
    font-weight: bold;
    color: #6F4F2A;
  }

  span:nth-child(3) {
    font-size: 0.36rem;
    font-family: SourceHanSerifCNHeavy;
    font-weight: bold;
    color: #654D36;
  }
}

.playProcess {
  width: 100%;
  height: 1.65rem;
  position: relative;
  background-image: url('../assets/img/528.png');
  background-position: left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 0.6rem;

  .playBtn {
    position: absolute;
    left: 64vw;
    top: 50%;
    transform: translate(0, -50%);
    object-fit: contain;
  }

  .voice {
    width: fit-content;
    position: absolute;
    left: 22vw;
    top: 50%;
    transform: translate(0, -50%);

    .voiceText {
      font-size: 0.36rem;
      font-family: SourceHanSerifCNHeavy;
      font-weight: bold;
      color: #64472A;
    }

    .processBar {
      width: 38vw;
      height: 0.1rem;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 0.05rem;
      position: relative;
      margin: 0.15rem 0 0.1rem 0;


      .activeLine {
        position: relative;
        // left: 0;
        // top: 0;
        width: 1rem;
        height: 100%;
        background: #574025;
        border-radius: 0.05rem;
        // z-index: 2;
      }

      .dot {
        width: 0.24rem;
        height: 0.24rem;
        background: #574025;
        border: 0.05rem solid #FFFFFF;
        border-radius: 50%;
        position: absolute;
        right: -0.12rem;
        top: 50%;
        transform: translate(0%, -50%);
        z-index: 3;
      }


    }

    .startEndSec {
      font-size: 0.26rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #906E45;
    }
  }


}

.countyContent {
  font-size: 0.36rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #906E45;
  line-height: 0.72rem;
  padding-right: 0.32rem;
  box-sizing: border-box;
}

.newAction {
  background: #F2EEE2;
  border: 0.03rem solid #FFFFFF;
  border-radius: 0.1rem;
  padding: 0.3rem;
  box-sizing: border-box;
  margin-right: 0.32rem;

  .newTitle {
    font-size: 0.4rem;
    font-family: SourceHanSerifCNHeavy;
    font-weight: bold;
    color: #64472A;
    line-height: 0.4rem;
  }

  .oneNew {
    border-bottom: 0.01rem solid rgba(151, 121, 86, 0.7);
    margin-bottom: 0.4rem;
    padding-bottom: 0.4rem;
  }

  .avatar {
    width: 2.95rem;
    height: 1.7rem;
    border-radius: 0.08rem;
    display: flex;
    margin-right: 0.3rem;
    flex-shrink: 0;
  }

  .rightContent {
    height: 1.7rem;
    flex-grow: 1;

    .contentText {
      font-size: 0.34rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #64472A;
      line-height: 0.44rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      -webkit-line-clamp: 2; //行数
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .timeDetail {
      font-size: 0.32rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #977956;
    }
  }
}
</style>
  
  